<!-- 厂家供货表 -->
<template>
  <div class="pagePadding home">
    <Form inline class="formMarginBtm20">
      <!-- 生产产商 -->
      <FormItem>
        <span>用户名称：</span>
        <Select placeholder="请输入" v-model="queryFrom.user_name" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in factoryList" :value="item.user_name" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <!-- 进货仓库 -->
      <FormItem class="marginLeft60">
        <span>操作内容：</span>
        <!-- <Select placeholder="请选择" v-model="queryFrom.warehouse_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in warehouseList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
        </Select> -->
        <Input v-model="queryFrom.operating_content" placeholder="请输入" class="iviewIptWidth250" />
      </FormItem>
      <!-- 采购时间 -->
      <FormItem class="marginLeft60">
        <span>操作时间：</span>
        <DatePicker type="date" @on-change="changeTime($event, 1)" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
        <span style="padding: 0 10px;">至</span>
        <DatePicker type="date" @on-change="changeTime($event, 2)" :options="options" v-model="queryFrom.operating_time_end" placeholder="请选择" class="iviewIptWidth250"></DatePicker>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginLeft60">
        <span class="pageBtn finger btnSure" @click="query()">查询</span>
      </FormItem>
    </Form>
    <div class="clearfix tabDiv" ref="tabDiv">
      <!--      <Spin fix v-if="Loading"></Spin>-->
      <Table :columns="listColumns" :loading="Loading" :data="listData" border class="table" :pages="queryFrom" :total="total" @change-page="changePage" totalText="条记录">
        <template slot-scope="{ row }" slot="operating_content">
          <div style="text-align: left;">{{ row.operating_content }}</div>
        </template>
      </Table>
      <div style="margintop: 20px;" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
        <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
        <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0 && !Loading">
        <img style="margin-top: 100px;" src="@/assets/images/tableNoData.png" />
      </div>
    </div>
  </div>
</template>

<script>
// import Table from '@/components/table'
export default {
  name: 'factorySupplyList',
  components: {
    // Table,
  },
  data() {
    return {
      options: {},
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 60,
          maxWidth: 60,
          key: 'index',
        },
        {
          title: '用户ID',
          key: 'login_name',
          align: 'center',
          width: 120,
        },
        {
          title: '用户名称',
          key: 'user_name',
          align: 'center',
          width: 110,
        },
        {
          title: 'IP地址',
          key: 'ip_address',
          align: 'center',
          minWidth: 120,
          maxWidth: 200,
        },
        {
          title: '日志类型',
          key: 'logs_type',
          align: 'center',
          // width: 150,
          width: 110,
        },
        {
          title: '操作内容',
          slot: 'operating_content',
          align: 'center',
          minWidth: 300,
        },
        {
          title: '操作时间',
          key: 'operating_time',
          align: 'center',
          minWidth: 200,
          maxWidth: 250,
        },
      ],
      // 厂家下拉列表
      factoryList: [],
      // 表单拉下选项
      thirdList: [],
      // 仓库下拉
      warehouseList: [],
      // 产品下拉
      productList: [],
      // 经手人列表
      userList: [],
      // 查询参数
      queryFrom: {
        operating_time_start: '',
        operating_time_end: '',
        operating_content: '',
        user_id: '',
        warehouse_id: '',
        factory_id: '',
        product_id: '',
        begin_time: '',
        end_time: '',
        product_model_code: '',
        specification_id_str: [],
        page: 1,
        rows: 10,
      },
      pages: {
        page: 1,
        rows: 10,
      },
      total: 0,
      supplied_varieties_tatol: '',
      purchase_quantity_tatol: '',
      purchase_amount_tatol: '',
      total_no_tax_sale_amount: '',
      total_no_tax_purchase_amount: '',
      Loading: false,
      tableHeight: '0',
      modelList: [],
    }
  },
  created() {},
  mounted() {
    this.getfactoryList()
    // this.getfactorySupplyList()
  },
  methods: {
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.operating_time_start = e
        that.queryFrom.operating_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.operating_time_start)
          },
        }
      } else {
        this.queryFrom.operating_time_end = e
      }
    },
    // 点击查询
    query() {
      this.queryFrom.page = 1
      // this.queryFrom.page = '1'
      this.getfactorySupplyList()
    },
    // 页码改变
    changePage(e) {
      this.queryFrom.page = e
      this.getfactorySupplyList()
    },
    // 获取厂家供货表
    getfactorySupplyList() {
      // console.log(this.pages.page,'this.pages.page')
      this.Loading = true
      let data = {
        page: this.queryFrom.page, // 页
        rows: this.queryFrom.rows, // 行
        operating_content: this.queryFrom.operating_content,
        user_name: this.queryFrom.user_name,
        operating_time_start: this.queryFrom.operating_time_start,
        operating_time_end: this.queryFrom.operating_time_end,
      }
      this.$http.get(this.$api.userlogsManage, data, false).then(res => {
        this.Loading = false
        this.total = res.data.total ? res.data.total : 0
        // 存储表格数据
        this.listData = res.data.result ? res.data.result : []
        if (this.listData.length > 0) {
          this.listData.forEach(item => {
            item.operating_time = item.operating_time ? this.$moment.unix(item.operating_time).format('YYYY-MM-DD HH:mm:ss') : ''
            item.logs_type = item.logs_type == '1' ? '产品修改' : ''
          })
        }
        this.$nextTick(() => {
          this.tableHeight = this.$refs.tabDiv.offsetHeight - 50 + ''
          this.$forceUpdate()
        })
      })
    },
    // 厂家下拉
    getfactoryList() {
      this.$http.get(this.$api.permissionQueryUser, false).then(res => {
        this.factoryList = res.data
      })
    },
    strInsert(str, length) {
      let reg = new RegExp('\\d{1,' + length + '}', 'g')
      let ma = str.match(reg)
      return ma.join(',')
    },
    // 数字转金额处理
    formatMoney(totalNum) {
      // if (typeof totalNum === 'number') {
      //   return totalNum + '.00'
      // } else {
      //   return totalNum
      // }
      let money = 0
      if (totalNum >= 0) {
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
      } else {
        totalNum = Math.abs(totalNum)
        let num = totalNum
        num = parseFloat((num + '').replace(/[^\d\.-]/g, '')).toFixed(2) + ''
        let valueArr = num.split('.')[0].split('').reverse()
        const valueFloat = num.split('.')[1]
        let valueString = ''
        for (let i = 0; i < valueArr.length; i++) {
          valueString += valueArr[i] + ((i + 1) % 3 == 0 && i + 1 != valueArr.length ? ',' : '') // 循环 取数值并在每三位加个','
        }
        money = valueString.split('').reverse().join('') + '.' + valueFloat // 拼接上小数位
        money = '-' + money
      }
      return money
    },
    summaryMethod({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        // key 就是每一列绑定的key
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计',
          }
          return
        }
        if (index === 1) {
          sums[key] = {
            key,
            value: '',
          }
          return
        }
        if (index === 2) {
          sums[key] = {
            key,
            value: this.supplied_varieties_tatol,
          }
          return
        }
        if (index === 3) {
          sums[key] = {
            key,
            value: this.purchase_quantity_tatol,
          }
          return
        }
        if (index === 4) {
          sums[key] = {
            key,
            // value: '¥' + this.strInsert(this.purchase_amount_tatol + '', 3) + '.00',
            value: '¥' + this.formatMoney(this.purchase_amount_tatol),
          }
          return
        }
        if (index === 5) {
          sums[key] = {
            key,
            value: '¥' + this.formatMoney(this.total_no_tax_purchase_amount),
          }
          return
        }
        if (index === 6) {
          sums[key] = {
            key,
            value: '100.00%',
          }
          return
        }
        if (index === 7) {
          sums[key] = {
            key,
            value: this.sale_quantity_tatol,
          }
          return
        }
        if (index === 8) {
          sums[key] = {
            key,
            // value: '¥' + this.strInsert(this.sale_amount_tatol + '', 3) + '.00',
            value: '¥' + this.formatMoney(this.sale_amount_tatol),
          }
          return
        }

        if (index === 9) {
          sums[key] = {
            key,
            // value: '¥' + this.strInsert(this.profit_total + '', 3) + '.00',
            value: '¥' + this.formatMoney(this.total_no_tax_sale_amount),
          }
          return
        }
        if (index === 10) {
          sums[key] = {
            key,
            // value: '¥' + this.strInsert(this.sale_amount_tatol + '', 3) + '.00',
            value: '¥' + this.formatMoney(this.profit_total),
          }
          return
        }
        const values = data.map(item => Number(item[key]))
        // every() 方法用于检测数组所有元素是否都符合指定条件
        // 如果数组中检测到有一个元素不满足，则整个表达式返回 false ，且剩余的元素不会再进行检测。
        // 如果所有元素都满足条件，则返回 true。
        // 如果有是NaN的

        // isNaN 是数字返回false 其他返回true
        // 检查values里面是否都是数字
        // 如果全都是数字的话
        if (!values.every(value => isNaN(value))) {
          // reduce用于累加
          const v = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[key] = {
            key,
            value: v,
          }
        } else {
          sums[key] = {
            key,
            value: '',
          }
        }
      })
      return sums
    },
  },
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
}
.tabDiv {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}
.marginTop20 {
  margin-top: 20px;
}
.table {
  /deep/ .ivu-table-summary {
    border-top: 0;
  }
}
</style>
